
 // @ is an alias to /src
import Navigation from '@/components/partials/Navigation.vue';
import Aside from '@/components/partials/Aside.vue';
import Footer from '@/components/partials/Footer.vue';
import { computed, defineComponent, reactive, ref } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
  name: "CreateExam",
  components: {
    Navigation,
    Aside,
    Footer
  },
  setup() {
    const store = useStore();
    const links = reactive([
      {
        name: "Create Exam",
        url: "exam/create"
      },
    ]);
    const levels = reactive(["JS1", "JS2", "JS3", "SS1", "SS2", "SS3"]);
    const exam = reactive({
        name: "Test Exam",
        limit: "30",
        date: "2020-08-17",
        time: "10:15",
        duration: "60",
        levels: [],
        status: false,
      });
    const name = ref("Exam");
    const nav = computed(() => store.getters.nav);

    return { name, nav, links, levels, exam };
  },
});
